import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Container, Divider, Dropdown, Form, Header, Menu, Modal, Segment, Table } from "semantic-ui-react";
import defaultMeetingEvents from "../assets/defaultMeetingEvents";
import AdminSelector from "../components/AdminSelector";
import { getAll } from "../features/calendar/calendarSlice";
import { getAllEventsFromCalendar } from "../features/events/eventSlice";
import { createMeetingEvent, getAllMeetings, getAllMeetingsEvents } from "../features/meetings/meetingSlice";




const AdminMeetings = () => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const { calendarsArr, isLoading: isLoadingCal } = useSelector((state) => state.calendars);

    const { eventsArr, isLoading: isLoadingEvents } = useSelector((state) => state.events);
    

    const { meetingsArr, meetingEventsArr, isLoading: isLoadingMeetings } = useSelector((state) => state.meetings);

    useEffect(() => {
        dispatch(getAll());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getAllMeetings());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getAllMeetingsEvents());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getAllEventsFromCalendar(id));
    }, [id, dispatch]);

    const calendar = useMemo(() => {
        return calendarsArr.find((calendar) => calendar?.id === id);
    }, [calendarsArr, id]);

    const meeting = meetingsArr.find(meeting => meeting.calendarid === id)

    const meetingEvents = useMemo(() => {
        //sort by event start day and time
        return meetingEventsArr.filter((event) => event?.meetingid === meeting?.id).sort((a, b) => {
            const aEvent = eventsArr?.find(event => event.id === a?.eventid);
            const bEvent = eventsArr?.find(event => event.id === b?.eventid);
            return aEvent?.startdate.localeCompare(bEvent?.startdate) || aEvent?.starttime.localeCompare(bEvent?.starttime);
        });
    }, [meetingEventsArr, meeting, eventsArr]);
    
    const events = eventsArr.filter((event) => event?.calendarid === id && event?.duration.includes("each") && !meetingEvents.some(meetingEvent => meetingEvent.eventid === event.id));
    

    const [showCreateEvent, setShowCreateEvent] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState();
    const [breakTime, setBreakTime] = useState(30);  //minutes
    const [slotDuration, setSlotDuration] = useState(30);  //minutes
    const [slots, setSlots] = useState([]);
    const [timezone, setTimezone] = useState('MT')
    const [createLoading, setCreateLoading] = useState(false);

    const calculateMaxSlots = (startTime, endTime, slotDuration) => {
        const startMinutes = parseInt(startTime.split(':')[0], 10) * 60 + parseInt(startTime.split(':')[1], 10);
        const endMinutes = parseInt(endTime.split(':')[0], 10) * 60 + parseInt(endTime.split(':')[1], 10);
    
        let totalSlots = 0;
        let currentTime = startMinutes;
    
        while (currentTime + slotDuration <= endMinutes) {
            totalSlots++;  // Schedule another slot
            currentTime += slotDuration;  // Move to the end of this slot
        }
    
        return totalSlots;
    }

    const cancelEventModal = (e) => {
        e?.preventDefault();
        setShowCreateEvent(false);
        setSelectedEvent();
        setBreakTime(30);
        setSlotDuration(30);
        setSlots([]);
    }

    const addSlot = (event, slotDuration, mode) => {
        if (!event) return;

        const startMinutes = parseInt(event.starttime.split(':')[0], 10) * 60 + parseInt(event.starttime.split(':')[1], 10);
        const slotnum = slots.length + 1;
        const nextTimeMinutes = startMinutes + slots.reduce((acc, slot) => acc + parseInt(slot.slotDuration, 10), 0);
        const nextTime = `${String(Math.floor(nextTimeMinutes / 60)).padStart(2, '0')}:${String(nextTimeMinutes % 60).padStart(2, '0')}`;

        if (mode === 1) {
            setSlots([...slots, { slotnum, time: nextTime, name: '', userid: '', slotDuration }]);
        } else if (mode === 2) {
            setSlots([...slots, { slotnum, time: nextTime, name: 'Break Time', userid: '', slotDuration }]);
        } else if (mode === 3) {
            setSlots([...slots, { slotnum, time: nextTime, name: 'Blocked Time', userid: '', slotDuration }]);
        }
    };

    //it should remove the last slot in the slots array
    const removeSlot = () => {
        if (slots.length > 0) {
            setSlots(slots.slice(0, slots.length - 1));
        }
    }

    const createEventMeeting = async () => {
        try {
            setCreateLoading(true);
            const breakSlots = slots.filter(slot => slot.name === 'Break Time').map(slot => slot.slotnum).join(',');
            const blockSlots = slots.filter(slot => slot.name === 'Blocked Time').map(slot => slot.slotnum).join(',');
    
            await dispatch(
                createMeetingEvent({
                    meetingid: meetingsArr?.find(meeting => meeting.calendarid === id).id,
                    eventid: selectedEvent?.id,
                    slots: slots.length,
                    slottime: slotDuration,
                    breaktime: breakTime,
                    breaks: breakSlots,
                    blocks: blockSlots,
                })
            ).unwrap();
            await dispatch(getAllMeetingsEvents()).unwrap();
            await dispatch(getAllEventsFromCalendar(id)).unwrap();
            toast.success('Event Meeting created successfully');
        } catch (error) {
            console.log(error);
            toast.error('Failed to create Event Meeting');
        } finally {
            setCreateLoading(false);
            //use cancelEventModal since all it does is reset the state
            cancelEventModal();
        }
    }

    const addDefaultMeetingEvents = (day) => {
        if (slots.length > 0) {
            setSlots([]);
        }

        switch (day) {
            case 1:
                setSlots(defaultMeetingEvents.event1);
                break;
            case 2:
                setSlots(defaultMeetingEvents.event2);
                break;
            default:
                break;
        }
    }

    const createEventModal = () => {

        const maxSlots = selectedEvent ? calculateMaxSlots(selectedEvent.starttime, selectedEvent.endtime, slotDuration) : 0;

        return (
            <>
                <Modal size="large" open={showCreateEvent} onClose={cancelEventModal}>
                    <Modal.Header>Create Event Meeting</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Form loading={createLoading}>
                                <Form.Dropdown
                                    label="Event"
                                    placeholder="Select Event"
                                    fluid
                                    search
                                    selection
                                    value={selectedEvent}
                                    options={events.map((event) => ({
                                        key: event.id,
                                        text: event.activity,
                                        value: event,
                                    }))}
                                    onChange={(e, { value }) => setSelectedEvent(value)}
                                />
                                <Form.Group widths="equal">
                                    <Form.Input
                                        label="Break Duration"
                                        type="number"
                                        placeholder="Enter Start Time"
                                        disabled={!selectedEvent}
                                        value={breakTime}
                                        onChange={(e) => setBreakTime(e.target.value)}
                                    />
                                    <Form.Input
                                        label="Slot Duration"
                                        type="number"
                                        placeholder="Enter Slot Duration"
                                        disabled={!selectedEvent}
                                        value={slotDuration}
                                        onChange={(e) => setSlotDuration(e.target.value)}
                                    />
                                </Form.Group>
                                <Divider />
                                <Segment basic disabled={!selectedEvent}>
                                    <Header as="h3" textAlign="center">Event Details
                                        <Header.Subheader>{selectedEvent?.startdate.split('T')[0]} {selectedEvent?.starttime} - {selectedEvent?.endtime}</Header.Subheader>
                                    
                                    </Header>
                                    <Button.Group fluid>
                                        <Button color="green" onClick={() => addDefaultMeetingEvents(1)}>Add Default 1</Button>
                                        <Button color="blue" onClick={() => addDefaultMeetingEvents(2)}>Add Default 2</Button>
                                    </Button.Group>
                                </Segment>
                                <Segment basic disabled={!selectedEvent}>
                                    <Header as="h3" textAlign="center">
                                        <Header.Subheader>Add breaks creates a break slot depending on the choosen duration, blocked time creates a blocked time slot, e.g., Q&A.</Header.Subheader>
                                    </Header>
                                    <Table celled textAlign="center">
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>Slot Number</Table.HeaderCell>
                                                <Table.HeaderCell>Time</Table.HeaderCell>
                                                <Table.HeaderCell>Participant</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {
                                                slots.map((slot) => {
                                                    if (slot.name === 'Break Time') {
                                                        return (
                                                            <Table.Row disabled warning key={`${slot.slotnum}`}>
                                                                <Table.Cell colSpan={3}>{slot.name}</Table.Cell>
                                                            </Table.Row>
                                                        )
                                                    } else if (slot.name === 'Blocked Time') {
                                                        return (
                                                            <Table.Row key={`${slot.slotnum}`}>
                                                                <Table.Cell colSpan='3'>{slot.name} at {slot.time}</Table.Cell>
                                                            </Table.Row>
                                                        )
                                                    } else {
                                                        return (
                                                            <Table.Row key={`${slot.slotnum}`}>
                                                                <Table.Cell>{slot.slotnum}</Table.Cell>
                                                                <Table.Cell>{slot.time}</Table.Cell>
                                                                <Table.Cell>{slot.name}</Table.Cell>
                                                            </Table.Row>
                                                        )
                                                    }
                                                })
                                            }
                                        </Table.Body>
                                        <Table.Footer>
                                            <Table.Row>
                                                <Table.HeaderCell colSpan={3}>
                                                    <Button.Group fluid>
                                                        <Button color="green" onClick={() => addSlot(selectedEvent, slotDuration, 1)} disabled={slots?.length >= maxSlots}>Add Slot</Button>
                                                        <Button color="blue" onClick={() => addSlot(selectedEvent, breakTime, 2)} disabled={slots?.length >= maxSlots}>Add Break</Button>
                                                        <Button color="yellow" onClick={() => addSlot(selectedEvent, slotDuration, 3)} disabled={slots?.length >= maxSlots}>Add Blocked Time</Button>
                                                        <Button color="red" onClick={removeSlot}>Remove Slot</Button>
                                                    </Button.Group>
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Footer>
                                    </Table>

                                </Segment>
                                <Form.Group widths="equal">
                                    <Form.Button
                                        color="blue"
                                        fluid
                                        disabled={!slots.length}
                                        onClick={createEventMeeting}
                                    >
                                        Create Event Meeting
                                    </Form.Button>
                                    <Form.Button
                                        color="red"
                                        fluid
                                        onClick={cancelEventModal}
                                    >
                                        Cancel
                                    </Form.Button>
                                </Form.Group>
                            </Form>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
            </>
        )
    }

    

    const timeOptions = useMemo(() => {
        return [
          { key: "MT", text: "Mountain Time", value: "MT" },
          { key: "PT", text: "Pacific Time", value: "PT" },
          { key: "CT", text: "Central Time", value: "CT" },
          { key: "ET", text: "Eastern Time", value: "ET" },
          { key: "AT", text: "Atlantic Time", value: "AT"}
        ];
    }, []);

    return ( 
        <Container>
            <Segment loading={isLoadingCal || isLoadingEvents || isLoadingMeetings} basic style={{ marginTop: "3em"}}>
                <Header as="h1" textAlign="center">{calendar?.title} Meetings</Header>
                <Segment>
                    <Menu size="huge" pagination stackable widths={2}>
                        <Dropdown item text={`Timezone: ${timezone}`} value={timezone} >
                            <Dropdown.Menu>
                                {
                                timeOptions.map((option) => (
                                    <Dropdown.Item key={option.key} {...option} onClick={() => setTimezone(option.value)} />
                                ))
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                        <Menu.Item onClick={() => setShowCreateEvent(true)}>Create Event Meeting</Menu.Item>
                    </Menu>
                </Segment>
                {createEventModal()}
                <Container>
                {
                    meetingEvents.map((meetingEvent) => {
                        const event = eventsArr.find(event => event.id === meetingEvent.eventid);
                        return (
                            <AdminSelector 
                                key={meetingEvent.eventid}
                                groupid={calendar?.groupid}
                                meeting={meetingEvent} 
                                event={event}
                                timezone={timezone}
                            />
                        );
                    })
                }
                </Container>
            </Segment>
           
        </Container>
    )
}

export default AdminMeetings