// Stylesheet
import { useGoogleLogin } from '@react-oauth/google';
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Divider, Form, Grid, Image, Popup, Segment } from 'semantic-ui-react';
import logo from "../assets/FL2F-logo.png";
import { googleLogin, login, reset } from "../features/auth/authSlice";
import { getAll } from "../features/calendar/calendarSlice";
import "../styles/login.scss";

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const params = new URLSearchParams(location.search);

    const code = params.get("code");

    const isMounted = useRef(false);

    const { calendarsArr } = useSelector((state) => state.calendars);

    useEffect(() => {
        dispatch(getAll());
    }, [dispatch]);

    useEffect(() => {
      if (isError) {
        setPassword("");
      }
  
    }, [isError]);

    useEffect(() => {
      if (user && user.role) {
        const calendar = calendarsArr.find(calendar => calendar.groupid === user.groupid);
        if (calendar && user.role === "participant") {
            navigate(`/meeting/${calendar.id}`);
        } else if (user.role === "admin") {
            navigate("/admin");
        }
      }
    }, [dispatch, navigate, user, calendarsArr]);

    useEffect(() => {
      if (code !== null && !isMounted.current) {
        handleGoogleLogin(code);
        isMounted.current = true;
      }
    }, [code]);

    const handleGoogleLogin = async (code) => {
      try {
        await dispatch(googleLogin(code)).unwrap();
        console.log("Google login successful");
        toast.success("Google login successful", { autoClose: 1250 });
        navigate("/");
      } catch (error) {
        console.error("Failed to handle Google login:", error);
        toast.error("Google login failed. Please try again.");
      }
    };

    const onClick = async () => {
      const calendar = calendarsArr.find(calendar => calendar?.groupid === user?.groupid);
      try {
        await dispatch(login({ username: username, password: password })).unwrap();
        if (user && user !== null && user?.role === "admin") {
            navigate("/admin");
        }
        if (user && user !== null && user?.role === "participant") {
            navigate(`/meeting/${calendar?.id}`);
        }
      } catch (error) {
        console.error("Failed to login:", error);
      }
    };

    const onClickGoogle = useGoogleLogin({
      onSuccess: () => console.log("success"),
      onError: () => console.log("error"),
      flow: 'auth-code',
      ux_mode: 'redirect',
    });  

    return (
        <Grid textAlign='center' style={{ height: '100vh'}} verticalAlign='middle'>
            <Grid.Column style={{ maxWidth: 450 }}>
                <Image src={logo}  size='medium' verticalAlign='middle'/>
                <Divider hidden />
                <Segment loading={isLoading} basic>
                  <Form size="huge" onSubmit={onClick} success={isSuccess} error={isError}>
                    <Segment size="big">
                      <Form.Input  
                        required 
                        label="Meeting Scheduler"
                        fluid 
                        icon='user' 
                        iconPosition='left' 
                        placeholder='Username' 
                        value={username} 
                        onChange={(e) => setUsername(e.target.value)}
                        {
                          ...isError && {
                            error: {
                              content: message,
    
                            }
                          }
                        } 
                        />
                      <Form.Input error={isError} required fluid icon='lock' iconPosition='left' placeholder='Password' type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                      <Button color='blue' fluid size='huge'>
                        Login
                      </Button>
                    </Segment>
                  </Form>
                  <Divider horizontal>Or</Divider>
                  <Form size='big'>
                    <Segment>
                    <button class="gsi-material-button" style={{width: "100%", marginTop: 3}} onClick={onClickGoogle}>
                      <div class="gsi-material-button-state"></div>
                      <div class="gsi-material-button-content-wrapper">
                        <div class="gsi-material-button-icon">
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" style={{display: "block"}}>
                            <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                            <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                            <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                            <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                            <path fill="none" d="M0 0h48v48H0z"></path>
                          </svg>
                        </div>
                        <span class="gsi-material-button-contents">Continue with Google</span>
                        <span style={{display: "none"}}>Continue with Google</span>
                      </div>
                    </button>
                    </Segment>
                  </Form>
                </Segment>
            </Grid.Column>
        </Grid>
    )

};

export default Login;
