import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import meetingService from "./meetingService";

const initialState = {
    meetingsArr: [],
    meetingEventsArr: [],
    meetingUsersArr: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};

export const getAllMeetings = createAsyncThunk(
    "meetings/getAll",
    async (_, thunkAPI) => {
        try {
            return await meetingService.getAllMeetings();
        } catch (error) {
            const message = error.response.data.message || error.message;
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getAllMeetingsEvents = createAsyncThunk(
    "meetings/getAllEvents",
    async (_, thunkAPI) => {
        try {
            return await meetingService.getAllMeetingsEvents();
        } catch (error) {
            const message = error.response.data.message || error.message;
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const initializeMeeting = createAsyncThunk(
    "meetings/initialize",
    async (meetingData, thunkAPI) => {
        try {
            return await meetingService.initializeMeeting(meetingData);
        } catch (error) {
            const message = error.response.data.message || error.message;
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const deleteMeeting = createAsyncThunk(
    "meetings/delete",
    async (meetingData, thunkAPI) => {
        try {
            return await meetingService.deleteMeeting(meetingData);
        } catch (error) {
            const message = error.response.data.message || error.message;
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const createMeetingEvent = createAsyncThunk(
    "meetings/createMtgEvent",
    async (meetingData, thunkAPI) => {
        try {
            return await meetingService.createMeetingEvent(meetingData);
        } catch (error) {
            const message = error.response.data.message || error.message;
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const removeMeetingEvent = createAsyncThunk(
    "meetings/removeMtgEvent",
    async (meetingData, thunkAPI) => {
        try {
            return await meetingService.removeMeetingEvent(meetingData);
        } catch (error) {
            const message = error.response.data.message || error.message;
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getUsersInMeeting = createAsyncThunk(
    "meetings/getUsers",
    async (meetingData, thunkAPI) => {
        try {
            return await meetingService.getUsersInMeeting(meetingData);
        } catch (error) {
            const message = error.response.data.message || error.message;
            return thunkAPI.rejectWithValue(message);
        }
    }
);


export const addUserToMeeting = createAsyncThunk(
    "meetings/addUser",
    async (meetingData, thunkAPI) => {
        try {
            return await meetingService.addUserToMeeting(meetingData);
        } catch (error) {
            const message = error.response.data.message || error.message;
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const removeUserFromMeeting = createAsyncThunk(
    "meetings/removeUser",
    async (meetingData, thunkAPI) => {
        try {
            return await meetingService.removeUserFromMeeting(meetingData);
        } catch (error) {
            const message = error.response.data.message || error.message;
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const meetingSlice = createSlice({
    name: "meeting",
    initialState,

    reducers: {
        reset: (state) => initialState,
    },

    extraReducers: (builder) => {
        builder
            // Get All
            .addCase(getAllMeetings.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllMeetings.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isError = false;
                state.message = "";
                state.meetingsArr = action.payload;
            })
            .addCase(getAllMeetings.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                state.message = action.payload;
                state.meetingsArr = [];
            })

            // Get All Events
            .addCase(getAllMeetingsEvents.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllMeetingsEvents.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isError = false;
                state.message = "";
                state.meetingEventsArr = action.payload;

            })
            .addCase(getAllMeetingsEvents.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                state.message = action.payload;
                state.meetingEventsArr = [];
            })

            // Initialize Meeting
            .addCase(initializeMeeting.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(initializeMeeting.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isError = false;
                state.message = action.payload;
                state.meetingsArr.push(action.payload);
            })
            .addCase(initializeMeeting.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                state.message = action.payload;
            })

            // Delete Initialized Meeting
            .addCase(deleteMeeting.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteMeeting.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isError = false;
                state.message = action.payload;
                state.meetingsArr = state.detialsArr.filter((meeting) => meeting.id !== action.payload.id);
            })
            .addCase(deleteMeeting.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                state.message = action.payload;
            })

            // Create Meeting Event
            .addCase(createMeetingEvent.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createMeetingEvent.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isError = false;
                state.message = action.payload;
                state.meetingEventsArr.push(action.payload);
            })
            .addCase(createMeetingEvent.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                state.message = action.payload;
            })

            // Remove Meeting Event
            .addCase(removeMeetingEvent.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(removeMeetingEvent.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isError = false;
                state.message = action.payload;
                //remove the event from the meetingEventsArr based on the eventid
                state.meetingEventsArr = state.meetingEventsArr.filter((event) => event.eventid !== action.payload.eventid);
            })
            .addCase(removeMeetingEvent.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                state.message = action.payload;
            })

            //get Users In Meeting
            .addCase(getUsersInMeeting.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getUsersInMeeting.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isError = false;
                state.message = "";
                state.meetingUsersArr = action.payload;
            })
            .addCase(getUsersInMeeting.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                state.message = action.payload;
                state.meetingUsersArr = [];
            })

            // Add User To Meeting
            .addCase(addUserToMeeting.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addUserToMeeting.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isError = false;
                state.message = action.payload;
                state.meetingsArr.push(action.payload);
            })
            .addCase(addUserToMeeting.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                state.message = action.payload;
            })

            // Remove User From Meeting
            .addCase(removeUserFromMeeting.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(removeUserFromMeeting.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isError = false;
                state.message = action.payload;
                //remove the user from the meetingUsersArr based on the userid and the eventid
                state.meetingUsersArr = state.meetingUsersArr.filter((user) => user.userid !== action.payload.userid || user.eventid !== action.payload.eventid);
            })
            .addCase(removeUserFromMeeting.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                state.message = action.payload;
            });
    },

});

export const { reset } = meetingSlice.actions;

export default meetingSlice.reducer;