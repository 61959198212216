const defaultMeetingEvents = {
    event1: [
        {
            "slotnum": 1,
            "time": "9:30:00 AM",
            "name": "",
            "userid": null,
            "slotDuration": 30
        },
        {
            "slotnum": 2,
            "time": "10:00:00 AM",
            "name": "",
            "userid": null,
            "slotDuration": 30
        },
        {
            "slotnum": 3,
            "time": "10:30:00 AM",
            "name": "Break Time",
            "userid": null,
            "slotDuration": 30
        },
        {
            "slotnum": 4,
            "time": "11:00:00 AM",
            "name": "",
            "userid": null,
            "slotDuration": 30
        },
        {
            "slotnum": 5,
            "time": "11:30:00 AM",
            "name": "",
            "userid": null,
            "slotDuration": 30
        },
    ],
    event2: [
        {
            "slotnum": 1,
            "time": "9:00:00 AM",
            "name": "",
            "userid": null,
            "slotDuration": 30
        },
        {
            "slotnum": 2,
            "time": "9:30:00 AM",
            "name": "",
            "userid": null,
            "slotDuration": 30
        },
        
        {
            "slotnum": 3,
            "time": "10:00:00 AM",
            "name": "",
            "userid": null,
            "slotDuration": 30
        },
        {
            "slotnum": 4,
            "time": "10:30:00 AM",
            "name": "Break Time",
            "userid": null,
            "slotDuration": 30
        },
        {
            "slotnum": 5,
            "time": "11:00:00 AM",
            "name": "",
            "userid": null,
            "slotDuration": 30
        },
        {
            "slotnum": 6,
            "time": "11:30:00 AM",
            "name": "",
            "userid": null,
            "slotDuration": 30
        }
    ],
    
};

export default defaultMeetingEvents;
