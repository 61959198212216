// Service is used for http requests, retreiving the data
import axios from "axios";

let API_URL = "";
if (process.env.NODE_ENV !== "production") {
  API_URL = "http://localhost:5000/api/"; // lets us use the backend server in local development
}
else {
  API_URL = "/api/";
}

// axios.defaults.withCredentials = true;

// Login User
const login = async (userData) => {
  try {
    const { data } = await axios.post(API_URL + "login", userData);
    if (data.username) {
      localStorage.setItem("user", JSON.stringify(data));
    }
    return data;
  } catch (error) {
    console.log("error from AuthService: ", error);
    throw error;
  }
};

const getAll = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await axios.get(API_URL + "login", config);
  return data;
};


const googleLogin = async (code) => {
  try {
    const { data } = await axios.post(API_URL + "google/login", { code });
    if (data.username) {
      localStorage.setItem("user", JSON.stringify(data));
    }
    return data;
  } catch (error) {
    console.log("error from AuthService: ", error);
    throw error;
  }
}

//logout CHANGE THIS TO HTTP ONLY COOKIE
const logout = () => {
  localStorage.removeItem("user");
};

const authService = {
  login,
  logout,
  getAll,
  googleLogin,
};

export default authService;
