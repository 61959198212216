import axios from "axios";

let API_URL = "";
if (process.env.NODE_ENV !== "production") {
  API_URL = "http://localhost:5000/api/calendars/"; // lets us use the backend server in local development
} else {
  API_URL = "/api/calendars/"
}

// GET all calendars
const getAll = async () => {
  const { data } = await axios.get(API_URL);
  return data;
};

// GET one calendar
const getOneCalendar = async (calendarID) => {
  const { data } = await axios.get(API_URL + calendarID);
  return data;
};

const calendarService = {
  getAll,
  getOneCalendar,
};

export default calendarService;
