import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./components/Navbar";
import PrivateRoutes from "./features/utils/PrivateRoutes";
import AdminMeetings from "./pages/AdminMeetings";
import Calendars from "./pages/Calendars";
import ErrorPage from "./pages/ErrorPage";
import Login from "./pages/Login";
import UserMeetings from "./pages/UserMeetings";




function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoutes />}> {/* Wrap protected routes inside PrivateRoutes */}
            <Route path="/admin" element={<Calendars />} />
            <Route path="/admin/meeting/:id" element={<AdminMeetings />} />
            <Route path="/meeting/:id" element={<UserMeetings />} />
          </Route>
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/*" element={<Navigate to={"/login"} />} />
        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
