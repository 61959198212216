import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import calendarReducer from "../features/calendar/calendarSlice";
import eventReducer from "../features/events/eventSlice";
import meetingReducer from "../features/meetings/meetingSlice";
import menuReducer from "../features/menu/menuSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    menu: menuReducer,
    calendars: calendarReducer,
    events: eventReducer,
    meetings: meetingReducer,
  },
});
