import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Container, Header, Segment, Table } from "semantic-ui-react";
import { v4 as uuidv4 } from "uuid";
import { getAll } from "../features/calendar/calendarSlice";
import { getAllMeetings, initializeMeeting } from "../features/meetings/meetingSlice";

const Calendars = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { calendarsArr, isLoading: isLoadingCal } = useSelector((state) => state.calendars);

    const { meetingsArr, isLoading: isLoadingMeetings } = useSelector((state) => state.meetings);


    useEffect(() => {
        dispatch(getAll());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getAllMeetings());
    }, [dispatch]);

    const initialize = async (calendarId) => {
        const meetingId = uuidv4();
        try {
            await dispatch(initializeMeeting({ id: meetingId, calendarid: calendarId,})).unwrap();
            toast.success("Meeting initialized successfully");
            dispatch(getAllMeetings());
            navigate(`/admin/meeting/${calendarId}`);
        } catch (error) {
            console.log(error);
            toast.error("Error initializing meeting");
        }
    }
    
    return (
        <Container>
            <Segment basic style={{ marginTop: "3em"}}>
                <Header as="h1" textAlign="center">Meeting Sheduler Dashboard
                    <Header.Subheader>Please ensure you have created a <a target="_blank" href="https://calendar.fl2f.ca" rel="noreferrer">calendar</a> and  <a target="_blank" href="https://user-management.fl2f.ca/" rel="noreferrer">users group</a> before initializing a schedule.</Header.Subheader>
                </Header>
                <Segment size="huge" padded raised loading={isLoadingCal || isLoadingMeetings}>
                    <Table textAlign="center" >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Calendar Name</Table.HeaderCell>
                                <Table.HeaderCell>Start Date</Table.HeaderCell>
                                <Table.HeaderCell>End Date</Table.HeaderCell>
                                <Table.HeaderCell>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            
                                {
                                    calendarsArr.map((calendar) => (
                                        <Table.Row key={calendar.id}>
                                            <Table.Cell>{calendar.title}</Table.Cell>
                                            <Table.Cell>{calendar.startdate.split('T')[0]}</Table.Cell>
                                            <Table.Cell>{calendar.enddate.split('T')[0]}</Table.Cell>
                                            {
                                                meetingsArr.find(meeting => meeting.calendarid === calendar.id) ? (
                                                    <Table.Cell>
                                                        <Button fluid color="green" size="large" onClick={() => navigate(`/admin/meeting/${calendar.id}`)} >View Meeting</Button>
                                                    </Table.Cell>
                                                ) : (
                                                    <Table.Cell>
                                                        <Button fluid color="blue" size="large" onClick={() => initialize(calendar.id)} >Initialize Meeting</Button>
                                                    </Table.Cell>
                                                )
                                            }
                                        </Table.Row>
                                    ))
                                
                                }
                            
                        </Table.Body>
                    </Table>
                </Segment>
            </Segment>
        </Container>
    )
}

export default Calendars