import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import calendarService from "./calendarService";

const initialState = {
  calendarsArr: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};


// GET All Calendars
export const getAll = createAsyncThunk(
  "calendars/getAll",
  async (_, thunkAPI) => {
    try {
      // should also pass in cookie informarion
      return await calendarService.getAll();
    } catch (error) {
      const message = error.response.data.message || error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Get individual calendar
export const getOneCalendar = createAsyncThunk(
  "calendars/getOne",
  async (calendarsId, thunkAPI) => {
    try {
      return await calendarService.getOneCalendar(calendarsId);
    } catch (error) {
      const message =
        error.response.data.message || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);



export const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  // this will delete everything in the calendars array
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder

      //Get All
      .addCase(getAll.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAll.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = "";
        state.calendarsArr = action.payload;
      })
      .addCase(getAll.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.calendarsArr = [];
      })

      //Get One
      .addCase(getOneCalendar.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOneCalendar.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.calendarsArr = action.payload;
      })
      .addCase(getOneCalendar.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.calendarsArr = [];
      })
  },
});

export const { reset } = calendarSlice.actions;

export default calendarSlice.reducer;
