import { createEvent, createEvents } from 'ics';
import fileDownload from 'js-file-download';
import { DateTime } from 'luxon';
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Divider, Dropdown, Header, Menu, Message, Popup, Segment, Table } from "semantic-ui-react";
import { getAll } from "../features/auth/authSlice";
import { getAllEventsFromCalendar } from "../features/events/eventSlice";
import { addUserToMeeting, getUsersInMeeting, removeMeetingEvent, removeUserFromMeeting } from "../features/meetings/meetingSlice";
//inconsisancy in the date display in calendar generator and the date display in the meeting selector, fix later
const AdminSelector = ({ meeting, event, timezone, groupid }) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const [slots, setSlots] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [registeredUserIds, setRegisteredUserIds] = useState([]);

    const { usersArr } = useSelector((state) => state.auth);

    const { meetingUsersArr } = useSelector((state) => state.meetings);

    useEffect(() => {
        dispatch(getAll());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getUsersInMeeting({ meetingid: meeting.meetingid }));
    }, [dispatch, meeting.meetingid]);

    useEffect(() => {
        if (meetingUsersArr.length > 0) {
            updateRegisteredUserIds();
        }
    }, [meetingUsersArr]);
    
    const updateRegisteredUserIds = () => {
        const ids = meetingUsersArr.filter(user => user.eventid === event?.id).map(user => user.userid);
        setRegisteredUserIds(ids);
    };

    const timezoneMapping = {
        MT: 'America/Denver',
        PT: 'America/Los_Angeles',
        CT: 'America/Chicago',
        ET: 'America/New_York',
        AT: 'America/Halifax'
    };

    const convertTimezone = (date, time, targetTimezone) => {
        const dateTimeInMT = DateTime.fromISO(`${date}T${time}`, { zone: 'America/Denver' });
        const dateTimeInTargetZone = dateTimeInMT.setZone(timezoneMapping[targetTimezone]);
        return dateTimeInTargetZone.toFormat('HH:mm:ss');
    };

    const generateSlots = (meeting, event, timezone) => {
        const slots = [];
        const breaks = meeting.breaks.split(',').map(Number);
        const blocks = meeting.blocks.split(',').map(Number);
        const slotDuration = meeting.slottime;

        const { startdate, starttime, endtime, id: eventid} = event;

        const startDateTime = DateTime.fromISO(`${startdate}T${starttime}`, { zone: 'America/Denver' });
        const endDateTime = DateTime.fromISO(`${startdate}T${endtime}`, { zone: 'America/Denver' });

        let currentTime = startDateTime;

        for (let i = 0; i < meeting.slots; i++) {
            if (currentTime > endDateTime) break;

            const meetingUser = meetingUsersArr.find(user => user.slotnum === i + 1 && user.eventid === event.id);

            let slot = {
                slotnum: i + 1,
                eventid: eventid,
                time: currentTime.setZone(timezoneMapping[timezone]).toFormat('HH:mm:ss'),
                name: meetingUser ? `${meetingUser.name} ${meetingUser.lastname}` : "",
                userid: meetingUser ? meetingUser.userid : null,
                slotDuration: slotDuration
            };

            if (blocks.includes(slot.slotnum)) {
                slot.name = "Blocked Time";
            } else if (breaks.includes(slot.slotnum)) {
                slot.name = "Break Time";
            }

            slots.push(slot);

            currentTime = currentTime.plus({ minutes: slotDuration });
        }

        return slots;
    };


    useEffect(() => {
        if (meeting && event) {
            setSlots(generateSlots(meeting, event, timezone));
        }
    }, [meeting, event, timezone, meetingUsersArr]);

    const startTime = event && event.startdate && event.starttime ? convertTimezone(event.startdate, event.starttime, timezone) : '';
    const endTime = event && event.startdate && event.endtime ? convertTimezone(event.startdate, event.endtime, timezone) : '';

    const bookSlot = async (slot) => {

        const existingUser = meetingUsersArr.find(user => user.slotnum === slot.slotnum && user.eventid === event.id);

        try {
            if (existingUser) { //if user already booked a slot, unbook it first so it gets replaced by the admin
                await dispatch(removeUserFromMeeting({ eventid: event.id, meetingid: meeting.meetingid, userid: existingUser.userid })).unwrap();
            }
            await dispatch(addUserToMeeting({ eventid: event.id, meetingid: meeting.meetingid, userid: slot.userid, slotnum: slot.slotnum })).unwrap();
            
            await dispatch(getUsersInMeeting({ meetingid: meeting.meetingid })).unwrap();
            toast.success('Slot booked successfully');
        } catch (error) {
            console.log(error);
            toast.error('Failed to book slot');
        } finally {// finally is used since we want to clear the selected slot regardless of the outcome
            setSelectedSlot(null);
        }
    }

    const unbookSlot = async (slot) => {
        try {
            await dispatch(removeUserFromMeeting({ eventid: event.id, meetingid: meeting.meetingid, userid: slot.userid })).unwrap();
            await dispatch(getUsersInMeeting({ meetingid: meeting.meetingid })).unwrap();
            toast.success('Slot unbooked successfully');
        } catch (error) {
            console.log(error);
            toast.error('Failed to unbook slot');
        } finally {
            setSelectedSlot(null);
        }
    }

    const generateIcsFile = (slot) => {
        const { startdate, activity, topic } = event;
        const [hours, minutes] = slot.time.split(':').map(Number);
      
        const dateTime = DateTime.fromISO(startdate).set({
          hour: hours,
          minute: minutes,
          second: 0,
          millisecond: 0,
        }).setZone(timezoneMapping[timezone]);
      
        return {
          start: [
            dateTime.year,
            dateTime.month,
            dateTime.day,
            dateTime.hour,
            dateTime.minute
          ],
          duration: { minutes: slot.slotDuration },
          title: `${activity} - ${slot.name}`,
          description: topic,
          location: timezoneMapping[timezone],
          url: window.location.href,
          status: 'CONFIRMED',
          busyStatus: 'BUSY',
        };
      };

    const getOneIcsFile = (slot) => {
        const event = generateIcsFile(slot);
        createEvent(event, (error, value) => {
            if (error) {
                console.log(error);
                toast.error('Failed to generate ICS file');
            } else {
                fileDownload(value, `${event.title}.ics`);
            }
        });
    }

    const generateIcsFiles = () => {
        const bookedSlots = slots.filter(slot => slot.userid && slot.name !== 'Blocked Time' && slot.name !== 'Break Time');

        if (bookedSlots.length === 0) {
            toast.warning('No slots booked for this meeting');
            return;
        }

        const events = bookedSlots.map(slot => generateIcsFile(slot));
        createEvents(events, (error, value) => {
            if (error) {
                console.log(error);
                toast.error('Failed to generate ICS files');
            } else {
                fileDownload(value, `${event.activity}.ics`);
            }
        });
    }

    //ondelete meeting, unbook all slots and delete meeting event
    const deleteMeeting = async () => {
        try {
            for (const slot of slots) {
                //unbook all slots before deleting the meeting
                if (slot.userid && slot.eventid === event.id) {
                    await dispatch(removeUserFromMeeting({ eventid: event.id, meetingid: meeting.meetingid, userid: slot.userid })).unwrap();
                }
            }
            await dispatch(removeMeetingEvent({ eventid: event.id, meetingid: meeting.meetingid })).unwrap();
            await dispatch(getAllEventsFromCalendar(id)).unwrap();
            toast.success('Meeting deleted successfully');
        } catch (error) {
            console.log(error);
            toast.error('Failed to delete meeting');
        }  
    }

    return (
        <Segment loading={slots?.length === 0}>
            <Header as='h3' textAlign="center" dividing>{event?.activity}</Header>
            <Menu attached="top" size="large" widths={4}>
                <Menu.Item>{DateTime.fromISO(`${event?.startdate}T${event?.starttime}`).toFormat('ccc DDD')}</Menu.Item>
                <Menu.Item>{startTime} - {endTime}</Menu.Item>
                <Menu.Item>{event?.duration}</Menu.Item>
                <Menu.Item>Break Time: {meeting?.breaktime} minutes</Menu.Item>
            </Menu>
            <Segment attached="bottom" basic>
                <Table basic="very" size="large" textAlign="center" selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Slot Number</Table.HeaderCell>
                            <Table.HeaderCell>Time</Table.HeaderCell>
                            <Table.HeaderCell>Participant</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            slots.map((slot) => {
                                if (slot.name === 'Break Time') {
                                    return (
                                        <Table.Row warning key={`${event.id}-${slot.slotnum}`}>
                                            <Table.Cell colSpan={4}>{slot.name}</Table.Cell>
                                        </Table.Row>
                                    )
                                } else if (slot.name === 'Blocked Time') {
                                    return (
                                        <Table.Row positive key={`${event.id}-${slot.slotnum}`}>
                                            <Table.Cell colSpan={4}>{slot.name} at {slot.time}</Table.Cell>
                                        </Table.Row>
                                    )
                                } else {
                                    return (
                                        <Fragment key={`${event.id}-${slot.slotnum}`}>
                                            <Table.Row onClick={() => setSelectedSlot(selectedSlot && selectedSlot.slotnum === slot.slotnum ? null : slot)} active={selectedSlot && selectedSlot.slotnum === slot.slotnum}>
                                                <Table.Cell>{slot.slotnum}</Table.Cell>
                                                <Table.Cell>{slot.time}</Table.Cell>
                                                <Table.Cell>{slot.name}</Table.Cell>
                                            </Table.Row>
                                            {selectedSlot && selectedSlot.slotnum === slot.slotnum && (
                                                <Table.Row>
                                                    <Table.Cell colSpan={3}>
                                                        <Segment basic>
                                                            <Header as='h3' textAlign="center" >Modify Meeting Slot
                                                                <Header.Subheader>Booked by: {slot.name}</Header.Subheader>
                                                            </Header>
                                                            {
                                                                usersArr.filter(user => user.groupid === groupid && !registeredUserIds.includes(user.id)).length === 0 && 
                                                                <Message warning>
                                                                    <Message.Header>No participants available to book</Message.Header>
                                                                </Message>
                                                            }
                                                            <Dropdown
                                                                placeholder='Select Participant'
                                                                fluid
                                                                selection
                                                                label='Select Participant'
                                                                options={usersArr.filter(user => user.groupid === groupid && !registeredUserIds.includes(user.id) && user.withdrawal !== 1)
                                                                    .map(user => ({ key: user.id, text: `${user.name} ${user.lastname}`, value: user.id }))}
                                                                value={selectedSlot.userid}
                                                                onChange={(e, { value }) => {
                                                                    const user = usersArr.find(user => user.id === value);
                                                                    setSelectedSlot({ ...selectedSlot, userid: value, name: `${user.name} ${user.lastname}` });
                                                                }}
                                                            />

                                                            <Divider hidden />
                                                            <Button.Group fluid>
                                                                <Button color='red' onClick={() => unbookSlot(selectedSlot)} disabled={slot.userid !== selectedSlot.userid}>Unbook Slot</Button>
                                                                <Button color='green' disabled={!selectedSlot.userid || slot.userid === selectedSlot.userid} onClick={() => bookSlot(selectedSlot)}>Book Slot</Button>
                                                                <Button color='blue' disabled={!selectedSlot.name} onClick={() => getOneIcsFile(selectedSlot)}>Download ICS</Button>
                                                                <Button color='black' onClick={() => setSelectedSlot(null)}>Cancel</Button>
                                                            </Button.Group>
                                                        </Segment>
                                                    </Table.Cell>
                                                </Table.Row>
                                            )}
                                        </Fragment>
                                    )
                                }
                            })
                        }
                    </Table.Body>
                </Table>
                {/* add a grid of for three buttons, one to download all ics files, one to download the ics file for the selected slot, and one to book the selected slot */}
                <Divider />
                <Button.Group fluid>
                    <Button color='blue' onClick={generateIcsFiles}>Download All ICS Files</Button>
                    {/* <Button color='green' >Edit Meeting</Button> */}
                    {/* add popup to add confirmation for the delete button */}
                    <Popup
                        content='Are you sure you want to delete this meeting? This will unbook all slots and delete the meeting event.'
                        trigger={<Button color='red' onClick={deleteMeeting}>Delete Meeting</Button>}
                        on='hover'
                        position='top'
                    />
                </Button.Group>
            </Segment>
        </Segment>
    )
}

export default AdminSelector