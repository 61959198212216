import axios from "axios";


let API_URL = "";
if (process.env.NODE_ENV !== "production") {
  API_URL = "http://localhost:5000/api/meetings/"; // lets us use the backend server in local development
} else {
  API_URL = "/api/meetings/"
}

//get all meetings
const getAllMeetings = async () => {
  const { data } = await axios.get(API_URL);
  return data;
};

//get one meeting

//get all meetings events
const getAllMeetingsEvents = async () => {
    const { data } = await axios.get(`${API_URL}events`);
    return data;
}

//initialize meeting
const initializeMeeting = async (meetingData) => {
  const { data } = await axios.post(API_URL, meetingData);
  return data;
};

//delete meeting
const deleteMeeting = async (meetingData) => {
  const { id } = meetingData;
  const { data } = await axios.delete(`${API_URL}${id}`, meetingData);
  return data;
};

//create meeting event
const createMeetingEvent = async (meetingData) => {
  const { meetingid, eventid } = meetingData;
  const { data } = await axios.post(`${API_URL}${meetingid}/events/${eventid}`, meetingData);
  return data;
};

//remove meeting event
const removeMeetingEvent = async (meetingData) => {
  const { meetingid, eventid } = meetingData;
  const { data } = await axios.delete(`${API_URL}${meetingid}/events/${eventid}`, meetingData);
  return data;
};

//get all users in meeting
const getUsersInMeeting = async (meetingData) => {
    const { meetingid } = meetingData;
    const { data } = await axios.get(`${API_URL}${meetingid}/users`);
    return data;
}


//add user to meeting
const addUserToMeeting = async (meetingData) => {
  const { meetingid } = meetingData;
  const { data } = await axios.post(`${API_URL}${meetingid}/users`, meetingData);
  return data;
};

//remove user from meeting
//for delete request, we need to pass the data in the config object, not sure why May 21
const removeUserFromMeeting = async (meetingData) => {
  const { meetingid, userid } = meetingData;
  const config = {
    data: meetingData,
  };
  const { data } = await axios.delete(`${API_URL}${meetingid}/users/${userid}`, config);
  return data;
};

const meetingService = {
  getAllMeetings,
  getAllMeetingsEvents,
  initializeMeeting,
  deleteMeeting,
  createMeetingEvent,
  removeMeetingEvent,
  getUsersInMeeting,
  addUserToMeeting,
  removeUserFromMeeting,
};

export default meetingService;