import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Container, Dropdown, Header, Menu, Pagination, Segment } from "semantic-ui-react";
import MeetingsSelector from "../components/MeetingsSelector";
import { getAll } from "../features/calendar/calendarSlice";
import { getAllEventsFromCalendar } from "../features/events/eventSlice";
import { getAllMeetings, getAllMeetingsEvents } from "../features/meetings/meetingSlice";

const UserMeetings = () => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const { calendarsArr, isLoading: isLoadingCal } = useSelector((state) => state.calendars);
    const { eventsArr, isLoading: isLoadingEvents } = useSelector((state) => state.events);
    const { meetingsArr, meetingEventsArr, isLoading: isLoadingMeetings } = useSelector((state) => state.meetings);

    useEffect(() => {
        dispatch(getAll());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getAllMeetings());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getAllMeetingsEvents());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getAllEventsFromCalendar(id));
    }, [id, dispatch]);

    const calendar = useMemo(() => {
        return calendarsArr.find((calendar) => calendar?.id === id);
    }, [calendarsArr, id]);

    const meeting = meetingsArr.find(meeting => meeting.calendarid === id);

    const meetingEvents = useMemo(() => {
        return meetingEventsArr.filter((event) => event?.meetingid === meeting?.id);
    }, [meetingEventsArr, meeting]);

    const [timezone, setTimezone] = useState('MT');
    const [activePage, setActivePage] = useState(1);
    const itemsPerPage = 1;

    const totalPages = Math.ceil(meetingEvents.length / itemsPerPage);

    const handlePaginationChange = (e, { activePage }) => setActivePage(activePage);

    const displayedMeetingEvents = meetingEvents.slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage);

    const timeOptions = useMemo(() => {
        return [
            { key: "MT", text: "Mountain Time", value: "MT" },
            { key: "PT", text: "Pacific Time", value: "PT" },
            { key: "CT", text: "Central Time", value: "CT" },
            { key: "ET", text: "Eastern Time", value: "ET" },
            { key: "AT", text: "Atlantic Time", value: "AT" }
        ];
    }, []);

    return (
        <Container>
            <Segment loading={isLoadingCal || isLoadingEvents || isLoadingMeetings} basic style={{ marginTop: "3em" }}>
                <Header as="h1" textAlign="center">{calendar?.title} Meetings</Header>
                <Segment>
                    <Menu size="huge" pagination stackable widths={1}>
                        <Dropdown item text={`Timezone: ${timezone}`} value={timezone} >
                            <Dropdown.Menu>
                                {
                                    timeOptions.map((option) => (
                                        <Dropdown.Item key={option.key} {...option} onClick={() => setTimezone(option.value)} />
                                    ))
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu>
                </Segment>
                <Segment>
                    <Pagination
                        fluid
                        activePage={activePage}
                        onPageChange={handlePaginationChange}
                        totalPages={totalPages}
                        siblingRange={1}
                        boundaryRange={0}
                        ellipsisItem={null}
                        firstItem={null}
                        lastItem={null}
                    />
                </Segment>
                <Container>
                    {
                        displayedMeetingEvents.map((meetingEvent) => {
                            const event = eventsArr.find(event => event.id === meetingEvent.eventid);
                            return (
                                <MeetingsSelector
                                    key={meetingEvent.eventid}
                                    meeting={meetingEvent}
                                    event={event}
                                    timezone={timezone}
                                />
                            );
                        })
                    }
                </Container>
            </Segment>
        </Container>
    );
}

export default UserMeetings;
