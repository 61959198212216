import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const PrivateRoutes = () => {
    const { user } = useSelector((state) => state.auth);
    const location = useLocation();

    if(location.pathname.includes('/undefined')) {
        window.location.reload();
    }

    if (!user) {
        // Redirect to login and remember the current location
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (user?.role === "admin") {
        return <Outlet />;
    } else if (user?.role === "participant") {
        if (location.pathname.startsWith("/meeting/")) {
            return <Outlet />;
        } else {
            return <Navigate to="/error" replace />;
        }
    } else {
        return <Navigate to="/error" replace />;
    }
};

export default PrivateRoutes;
