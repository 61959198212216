import { createEvent, createEvents } from 'ics';
import fileDownload from 'js-file-download';
import { DateTime } from 'luxon';
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Header, Menu, Message, Segment, Table } from "semantic-ui-react";
import { addUserToMeeting, getUsersInMeeting, removeUserFromMeeting } from "../features/meetings/meetingSlice";
//inconsisancy in the date display in calendar generator and the date display in the meeting selector, fix later
const MeetingsSelector = ({meeting, event, timezone}) => {
    const dispatch = useDispatch();

    const [slots, setSlots] = useState([]);

    const { user } = useSelector((state) => state.auth);

    const { meetingUsersArr } = useSelector((state) => state.meetings);

    const timezoneMapping = {
        MT: 'America/Denver',
        PT: 'America/Los_Angeles',
        CT: 'America/Chicago',
        ET: 'America/New_York',
        AT: 'America/Halifax'
    };

    const convertTimezone = (date, time, targetTimezone) => {
        const dateTimeInMT = DateTime.fromISO(`${date}T${time}`, { zone: 'America/Denver' });
        const dateTimeInTargetZone = dateTimeInMT.setZone(timezoneMapping[targetTimezone]);
        return dateTimeInTargetZone.toFormat('HH:mm:ss');
    };

    useEffect(() => {
        dispatch(getUsersInMeeting({ meetingid: meeting.meetingid }));
    }, [dispatch, meeting.meetingid]);

    useEffect(() => {
        if (meeting && event) {
            setSlots(generateSlots(meeting, event, timezone));
        }
    }, [meeting, event, timezone, meetingUsersArr]);

    //slots are generated by the time duration of the slots and the index positions of the breaks and blocks
    //e.g. if a slot has a time durition of 15 minutes and there is a break at slot 3 and a block at slot 5
    //the slots will be generated as follows:
    //1. 9:00 AM
    //2. 9:15 AM
    //3. Break Time
    //4. 9:30 AM
    //5. Blocked Time
    //6. 9:45 AM
    //7. 10:00 AM
    const generateSlots = (meeting, event, timezone) => {
        const slots = [];
        const breaks = meeting.breaks.split(',').map(Number);
        const blocks = meeting.blocks.split(',').map(Number);
        const slotDuration = meeting.slottime;

        const { startdate, starttime, endtime } = event;

        const startDateTime = DateTime.fromISO(`${startdate}T${starttime}`, { zone: 'America/Denver' });
        const endDateTime = DateTime.fromISO(`${startdate}T${endtime}`, { zone: 'America/Denver' });

        let currentTime = startDateTime;

        for (let i = 0; i < meeting.slots; i++) {
            if (currentTime > endDateTime) break;

            const meetingUser = meetingUsersArr.find(user => user.slotnum === i + 1 && user.eventid === event.id);

            let slot = {
                slotnum: i + 1,
                time: currentTime.setZone(timezoneMapping[timezone]).toFormat('HH:mm:ss'),
                name: meetingUser ? `${meetingUser.name} ${meetingUser.lastname}` : "",
                userid: meetingUser ? meetingUser.userid : null,
                slotDuration: slotDuration
            };

            if (blocks.includes(slot.slotnum)) {
                slot.name = "Blocked Time";
            } else if (breaks.includes(slot.slotnum)) {
                slot.name = "Break Time";
            }

            slots.push(slot);

            currentTime = currentTime.plus({ minutes: slotDuration });
        }

        return slots;
    };

    const startTime = event && event.startdate && event.starttime ? convertTimezone(event.startdate, event.starttime, timezone) : '';
    const endTime = event && event.startdate && event.endtime ? convertTimezone(event.startdate, event.endtime, timezone) : '';

    const bookSlot = async (slotnum) => {
        try {
            await dispatch(addUserToMeeting({ eventid: event.id, userid: user.id, meetingid: meeting.meetingid, slotnum})).unwrap();
            dispatch(getUsersInMeeting({ meetingid: meeting.meetingid }));  
        } catch (error) {
            console.log(error);
            toast.error('Failed to book slot');
        } finally {
            toast.success('Slot booked successfully');
        }
    }

    const unbookSlot = async (slotnum) => {
        try {
            await dispatch(removeUserFromMeeting({ eventid: event.id, meetingid: meeting.meetingid, userid: user.id})).unwrap();
            dispatch(getUsersInMeeting({ meetingid: meeting.meetingid }));  
        } catch (error) {
            console.log(error);
            toast.error('Failed to unbook slot');
        } finally {
            toast.success('Slot unbooked successfully');
        }
    }

    const userHasMeeting = meetingUsersArr?.some(meetingUser => meetingUser?.userid === user?.id && meetingUser?.eventid === event?.id);

    const generateIcsFile = (slot) => {
        const { startdate } = event;

        const [hours, minutes, period] = slot.time.match(/(\d+):(\d+):\d+\s(\w+)/).slice(1);
        const date = new Date(startdate);
        date.setHours(period === 'PM' ? Number(hours) + 12 : Number(hours), Number(minutes), 0, 0);

        return {
            start: [
                date.getFullYear(),
                date.getMonth() + 1,
                date.getDate(),
                date.getHours(),
                date.getMinutes()
            ],
            duration: { minutes: slot.slotDuration },
            title: event.activity,
            description: event.topic,
            location: timezoneMapping[timezone],
            url: window.location.href,
            status: 'CONFIRMED',
            busyStatus: 'BUSY',
            attendees: [{ name: slot.name, email: user.email }]
        };
    };

    const getOneIcsFile = (slot) => {
        const event = generateIcsFile(slot);
        createEvent(event, (error, value) => {
            if (error) {
                console.log(error);
                toast.error('Failed to generate ICS file');
            } else {
                fileDownload(value, `${event.title}.ics`);
            }
        });
    }

    const generateIcsFiles = () => {
        const events = slots.filter(slot => slot.userid === user.id).map(slot => generateIcsFile(slot));
        createEvents(events, (error, value) => {
            if (error) {
                console.log(error);
                toast.error('Failed to generate ICS files');
            } else {
                fileDownload(value, `${event.activity}.ics`);
            }
        });
    }

    
    return (
        <Segment loading={slots?.length === 0}>
            <Header as='h3' textAlign="center" dividing>{event?.activity}</Header>
            {!userHasMeeting && (
                <Message warning>
                    <Message.Header>You have not booked a time slot in this meeting</Message.Header>
                    <p>Please ensure you have scheduled a slot before the meeting starts</p>
                </Message>
            )}
            <Menu stackable attached="top" size="large" widths={5}>
                <Menu.Item>{DateTime.fromISO(`${event?.startdate}T${event?.starttime}`).toFormat('ccc DDD')}</Menu.Item>
                <Menu.Item>{startTime} - {endTime}</Menu.Item>
                <Menu.Item>{event?.duration}</Menu.Item>
                <Menu.Item>Slots: {meeting?.slots}</Menu.Item>
                <Menu.Item>Break Time: {meeting?.breaktime} minutes</Menu.Item>
            </Menu>
            <Segment attached="bottom" basic>
                <Table basic="very" size="large" textAlign="center" attached="top">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Slot Number</Table.HeaderCell>
                            <Table.HeaderCell>Time</Table.HeaderCell>
                            <Table.HeaderCell>Participant</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            //key needs to have event.id and slot.slotnum since slot.slotnum is a simple index and will be repeated for each event
                            slots.map((slot) => {
                                if (slot.name === 'Break Time') {
                                    return (
                                        <Table.Row warning key={`${event.id}-${slot.slotnum}`}>
                                            <Table.Cell colSpan={4}>{slot.name}</Table.Cell>
                                        </Table.Row>
                                    )
                                } else if (slot.name === 'Blocked Time') {
                                    return (
                                        <Table.Row warning key={`${event.id}-${slot.slotnum}`}>
                                            <Table.Cell colSpan={4}>{slot.name} at {slot.time}</Table.Cell>
                                        </Table.Row>
                                    )
                                } else {
                                    
                                    return (
                                        <Fragment key={`${event.id}-${slot.slotnum}`}>
                                            <Table.Row positive={slot.userid === user.id} negative={slot.userid && slot.userid !== user.id}>
                                                <Table.Cell>{slot.slotnum}</Table.Cell>
                                                <Table.Cell>{slot.time}</Table.Cell>
                                                <Table.Cell>{slot.name}</Table.Cell>
                                                <Table.Cell>
                                                    <Button.Group fluid>
                                                        {slot.userid === user.id ? (
                                                            <>
                                                                <Button color='red' onClick={() => unbookSlot(slot.slotnum)}>Unbook Slot</Button>
                                                                <Button color='blue' onClick={() => getOneIcsFile(slot)}>ICS File</Button>
                                                            </>
                                                        ) : (
                                                            <Button color='green' disabled={userHasMeeting || slot.userid} onClick={() => bookSlot(slot.slotnum)}>Book Slot</Button>
                                                        )}
                                                        
                                                    </Button.Group>

                                                </Table.Cell>
                                            </Table.Row>
                                        </Fragment>
                                    )
                                }
                            })
                        }
                    </Table.Body>
                </Table>
            </Segment>
        </Segment>
    )
}

export default MeetingsSelector